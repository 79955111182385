.info{
    &.info-hover{
        .info-title{
            transition: color .4s;
        }

        &:hover{
            .icon{
                @include transform-translate-y(-.5rem);

                &.icon-primary{
                    &.icon-circle{
                        box-shadow: 0px 15px 30px 0px rgba(249, 99, 50, 0.3);
                    }
                }
                &.icon-info{
                    &.icon-circle{
                        box-shadow: 0px 15px 35px 0px rgba(44, 168, 255, 0.3);
                    }
                }
                &.icon-success{
                    &.icon-circle{
                        box-shadow: 0px 15px 35px 0px rgba(24, 206, 15, 0.3);
                    }
                }
                &.icon-warning{
                    &.icon-circle{
                        box-shadow: 0px 15px 35px 0px rgba(255, 178, 54, 0.3);
                    }
                }
                &.icon-danger{
                    &.icon-circle{
                        box-shadow: 0px 15px 35px 0px rgba(255, 54, 54, 0.3);
                    }
                }

                &.icon-info + .info-title{
                    color: $info;
                }

                &.icon-warning + .info-title{
                    color: $warning;
                }

                &.icon-danger + .info-title{
                    color: $danger;
                }

                &.icon-primary + .info-title{
                    color: $primary;
                }

                &.icon-success + .info-title{
                    color: $success;
                }
            }
        }
    }

    .icon{
        color: $default;
        transition: transform .4s, box-shadow .4s;

        > i{
            font-size: 2.3em;
        }

        &.icon-circle{
            max-width: 70px;
            width: 70px;
            height: 70px;
            margin: 0 auto;
            border-radius: 50%;
            box-shadow: 0px 9px 35px -6px rgba(0, 0, 0, 0.3);
            font-size: $font-size-xs;
            background-color: $white;
            position: relative;

            i{
                line-height: 2.6em;
            }
        }
    }

    .info-title{
        margin: $margin-base-vertical 0 5px;
        padding: 0 15px;
        color: $black;
        font-weight: $font-weight-bold;
    }
    p{
        color: $default;
        padding: 0 15px;
        font-size: 1.1em;
    }
}

.info-horizontal{
    text-align: left !important;

    .icon{
        float: left;
        margin-top: 23px;
        margin-right: 10px;

        >i{
            font-size: 2em;
        }

        &.icon-circle{
            width: 65px;
            height: 65px;
            max-width: 65px;
            margin-top: 8px;

            i{
                display: table;
                margin: 0 auto;
                line-height: 3.5;
                font-size: 1.9em;
            }
        }
    }
    .description{
        overflow: hidden;
    }

}

.icon{
    &.icon-primary{
        color: $brand-primary;

        &.icon-circle{
            box-shadow: 0px 9px 30px -6px rgba($primary, .5);
        }
    }
    &.icon-info{
        color: $brand-info;

        &.icon-circle{
            box-shadow: 0px 9px 30px -6px rgba($info, .5);
        }
    }
    &.icon-success{
        color: $brand-success;

        &.icon-circle{
            box-shadow: 0px 9px 30px -6px rgba($success, .5);
        }
    }
    &.icon-warning{
        color: $brand-warning;

        &.icon-circle{
            box-shadow: 0px 9px 30px -6px rgba($warning, .5);
        }
    }
    &.icon-danger{
        color: $brand-danger;

        &.icon-circle{
            box-shadow: 0px 9px 30px -6px rgba($danger, .5);
        }
    }

    &.icon-white{
        color: $white;
    }
}
