.modal {
  &.fade .modal-dialog {
    animation-duration: unset;
    animation-fill-mode: unset;
    animation-name: unset;
  }
  &.show.modal-long .modal-dialog {
    transform: translateY(10%);
  }
}
