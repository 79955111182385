.login {
  overflow: hidden;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.7), 
    rgba(0, 0, 0, 0.7)
  ),url('../../assets/Artboard.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.login .container {
  width: 300px;
}