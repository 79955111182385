.fixed-plugin {
  li.adjustments-line {
    height: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    .togglebutton {
      .label-switch:last-child {
        left: unset;
        right: -10px;
      }
    }
  }
  li.header-title {
    height: auto;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  li.button-container {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
